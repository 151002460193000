// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import 'vue-class-component/hooks'; // import hooks type to enable auto-complete
import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import router from './router';
import * as config from './shared/config/config';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import ActivateService from './account/activate/activate.service';
import RegisterService from './account/register/register.service';
import UserManagementService from './admin/user-management/user-management.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';
import VueCompositionAPI from '@vue/composition-api';
import '@/libs/acl';
import '@/libs/vue-select';
import VueApexCharts from 'vue-apexcharts';
import Multiselect from 'vue-multiselect';
import H8TableGrid from './core/h8-component/h8-table-grid.vue';
// import LiquorTree from 'liquor-tree'

//import '../content/scss/global.scss';
//import '../content/scss/vendor.scss';
import TranslationService from '@/locale/translation.service';
import RoleService from './entities/role/role.service';
import vuetify from '@/libs/vuetify';
import H8Window from '@/core/h8-component/h8-window.vue';
import H8Table from '@/core/h8-component/h8-table.vue';
import H8Card from '@/core/h8-component/h8-card.vue';
import H8Dialog from './core/h8-component/h8-dialog.vue';
import H8Overlay from './core/h8-component/h8-overlay.vue';
import HomeService from './account/home.service';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
//Fusion Charts

/* tslint:disable */

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
const loginService = new LoginService();
Vue.config.productionTip = false;
let onUnauthenticated = () => {
  loginService.logout();
};
config.initVueApp(Vue, onUnauthenticated);
Vue.use(Vue2Filters);
Vue.use(VueCompositionAPI);
Vue.use(VueApexCharts);
Vue.use(VueToast);
// Vue.use(LiquorTree)
// Vue.component(LiquorTree.name, LiquorTree)
Vue.component('apexchart', VueApexCharts);
// register VueFusionCharts component
Vue.component('multiselect', Multiselect);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
Vue.component('h8-window', H8Window);
Vue.component('h8-table', H8Table);
Vue.component('h8-card', H8Card);
Vue.component('h8-dialog', H8Dialog);
Vue.component('h8-overlay', H8Overlay);
Vue.component('h8-table-grid', H8TableGrid);
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);
const accountService = new AccountService(store, translationService, router);

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
    return;
  }
  if (to.path == '/login' || to.path == '/forbidden' || to.name == 'ResetPasswordFinish') {
    next();
    return;
  }
  accountService.hasAnyAuthorityAndCheckAuth(to.meta.resource, to.meta.action).then(value => {
    if (!accountService.authenticated) {
      sessionStorage.setItem('requested-url', to.fullPath);
      next('/login');
      return;
    }
    //const authorized = true;//canNavigate(to);
    if (!value) {
      next('/forbidden');
      return;
    }
    next();
  });
});

/* tslint:disable */
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  vuetify,
  provide: {
    loginService: () => loginService,
    activateService: () => new ActivateService(),
    registerService: () => new RegisterService(),
    userManagementService: () => new UserManagementService(),
    roleService: () => new RoleService(),
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    translationService: () => translationService,
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    alertService: () => new AlertService(),
    homeService: () => new HomeService(),
  },
  i18n,
  store,
});
