import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IUser } from '@/shared/model/user.model';
import { OrgWiseRole } from '@/shared/model/org-wise-roles.model';
const baseApiUrl = 'api/users';
const baseSearchApiUrl = 'api/admin/_search/users?query=';
export default class UserManagementService {
  public get(userId: string,organizationId:number): Promise<any> {
    return axios.get(`api/admin/users/${organizationId}/${userId}`);
  }

  public create(user: IUser): Promise<any> {
    return axios.post('api/admin/users', user);
  }

  public update(user: IUser): Promise<any> {
    return axios.put('api/admin/users', user);
  }

  public delete(userId: string): Promise<any> {
    return axios.delete(`api/admin/users/${userId}`);
  }

  public retrieve(): Promise<any> {
    return axios.get(`api/admin/users`);
  }

  public retriveOrgWiseRoles(orgWiseRoles : any) : Promise<any> {
    return axios.post(`api/admin/users/orgwiseusers`,orgWiseRoles);
  }
  public logout(): Promise<boolean> {
    return new Promise(resolve => {
      axios
        .post<any>('/api/logout')
        .then(res => {
          resolve(true);
        })
        .catch(() => resolve(false));
    });
  }

  public search(query, paginationQuery): Promise<any> {
    const encodedQuery = encodeURIComponent(query);
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseSearchApiUrl}${encodedQuery}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public find(id: string): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
