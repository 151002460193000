import Vue from 'vue';
import { Component, Inject } from 'vue-property-decorator';
import LayoutFull from '@/layout-full.vue';
import LayoutVertical from '@/layout-vertical.vue';
import '@/shared/config/dayjs';

import TranslationService from '@/locale/translation.service';

@Component({
  components: {
    LayoutFull,
    LayoutVertical,
  },
})
export default class App extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;
  private currentLanguage = this.$store.getters.currentLanguage;

  created() {
    this.translationService().refreshTranslation(this.currentLanguage);
  }

  public get layout() {
    if (this.$route.meta.layout) {
      return 'layout-' + this.$route.meta.layout;
    }
    return 'layout-vertical';
  }
}
