class CommonUtils {
  static responsiveLargeChartOptions() {
    return [
      {
        breakpoint: 340,
        options: {
          chart: {
            width: 240,
            height: 300,
          },
        },
      },
      {
        breakpoint: 540,
        options: {
          chart: {
            width: 300,
            height: 300,
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 480,
          },
        },
      },
      {
        breakpoint: 960,
        options: {
          chart: {
            width: 800,
            height: 300,
          },
        },
      },
      {
        breakpoint: 1280,
        options: {
          chart: {
            width: 420,
            height: 300,
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            width: 520,
            height: 300,
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 600,
            height: 300,
          },
        },
      },
    ];
  }
  static responsiveSmallChartOptions() {
    return [
      {
        breakpoint: 340,
        options: {
          chart: {
            width: 300,
            height: 300,
          },
        },
      },
      {
        breakpoint: 540,
        options: {
          chart: {
            width: 400,
            height: 300,
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 350,
            height: 300,
          },
        },
      },
      {
        breakpoint: 960,
        options: {
          chart: {
            width: 300,
            height: 300,
          },
        },
      },
      {
        breakpoint: 1280,
        options: {
          chart: {
            width: 250,
            height: 300,
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 300,
            height: 300,
          },
        },
      },
    ];
  }
  static getMonitorComponentByType(type) {
    const filetype = {
      SNMP_INTERFACE_MONITOR: 'monitor-snmp-interface',
    };
    return filetype[type];
  }

  static getJobComponentByType(type) {
    const filetype = {
      FIRMWARE_UPGRADE: 'job-type-firmware-upgrade',
      PING_DIAGNOSTICS: 'job-type-ping-diagnostics',
      TRACE_ROUTE_DIAGNOSTICS: 'job-type-trace-route-diagnostics',
      GET_PARAMETER_VALUES: 'job-type-get-parameter-values',
      SET_WLAN_PARAMETER: 'job-type-set-wlan-parameter',
      SET_WAN_PARAMETER: 'job-type-set-wan-parameter',
      SET_PARAMETER: 'job-type-set-parameter',
      BLACKLIST_MAC_ADDRESS: 'job-type-blacklist-mac-address',
      VOIP: 'JobTypeVoip',
      SET_DHCP_PARAMETER: 'job-type-set-dhcp-parameter',
      NSLOOKUP_DIAGNOSTICS: 'job-type-ns-lookup-diagnostics',
    };
    return filetype[type];
  }

  static getRecurringBulkJobComponentByJobType(type) {
    const filetype = {
      FIRMWARE_UPGRADE: 'recurring-bulk-job-type-firmware-upgrade',
    };
    return filetype[type];
  }

  static formatBytes(bytes, type, decimals = 3) {
    let k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    let sizes = null;
    if (type == 'size') {
      if (bytes === 0) return '0 Bytes';
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    } else if (type == 'speed') {
      k = 1000;
      if (bytes === 0) return '0 Kbps';
      sizes = ['Bytes', 'Kbps', 'Mbps', 'Gbps'];
    }
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  static lastUpdateTime(lastUpdateTime, dateFormater) {
    const date = new Date();
    let current_day = date.getDate();
    let current_month = date.getMonth();
    let current_year = date.getFullYear();

    if (
      current_day == lastUpdateTime.getDate() &&
      current_month == lastUpdateTime.getMonth() &&
      current_year == lastUpdateTime.getFullYear()
    ) {
      return (
        'today at ' +
        lastUpdateTime.toLocaleTimeString('en-US', {
          // en-US can be set to 'default' to use user's browser settings
          hour: '2-digit',
          minute: '2-digit',
        })
      );
    }
    const yesterday = new Date(date.getTime() - 86400000);
    if (
      yesterday.getDate() == lastUpdateTime.getDate() &&
      yesterday.getMonth() == lastUpdateTime.getMonth() &&
      yesterday.getFullYear() == lastUpdateTime.getFullYear()
    ) {
      return (
        'yesterday at ' +
        lastUpdateTime.toLocaleTimeString('en-US', {
          // en-US can be set to 'default' to use user's browser settings
          hour: '2-digit',
          minute: '2-digit',
        })
      );
    }

    return dateFormater(lastUpdateTime);
  }
  static timeSince(date) {
    var seconds = Math.floor((Date.now() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + ' Years';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' Months';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' Days';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' Hours';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' Minutes';
    }
    return Math.floor(seconds) + ' Seconds';
  }

  static formatdBm(value) {
    if (value == null) return 'N/A';
    if (value == 0) {
      value = 0.0999;
    }
    return (10 * Math.log((value / 1000000) * 1000) * Math.LOG10E).toFixed(2);
  }

  static formatuW(value) {
    if (value == null) return 'N/A';
    return ((Math.pow(10, value / 10) / 1000) * 1000000).toFixed(2);
  }

  static formatCV(value) {
    if (value == null) return 'N/A';
    return (value / 1000).toFixed(2);
  }

  static buildSearchQuery(currentSearch, key, value) {
    if (key == undefined || value == undefined || key.length == 0 || value.length == 0) return currentSearch;
    if (currentSearch == undefined || currentSearch.length == 0) {
      return key + ': "' + value + '"';
    }
    return '(' + key + ': "' + value + '") && ' + currentSearch;
  }

  static getFeaturePermissions(featureName) {
    const featureListStr = sessionStorage.getItem('Features');
    const featureList = JSON.parse(featureListStr);
    const enabled = featureList?.find(feature => feature.feature === featureName)?.enabled;
    if (enabled !== undefined && enabled !== null) return enabled;
    return true;
  }
}

export default CommonUtils;
