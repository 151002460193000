import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
const baseApiUrl = 'api/dashboard';
export default class HomeService {
  public device(isTR069: boolean): Promise<any> {
    return axios.get(baseApiUrl + `/device?isTR069=${isTR069}`);
  }
  public monitor(type): Promise<any> {
    return axios.get(baseApiUrl + `/monitor?type=${type}`);
  }
  public alert(isTR069: boolean): Promise<any> {
    return axios.get(baseApiUrl + `/alert?isTR069=${isTR069}`);
  }
  public alertByDevice(isTR069: boolean): Promise<any> {
    return axios.get(baseApiUrl + `/alert-by-device?isTR069=${isTR069}`);
  }

  public deviceCountByModelName(provisioningMode: string): Promise<any> {
    return axios.get(baseApiUrl + `/count-by-model-and-version?provisioningMode=${provisioningMode}`);
  }

  public deviceCountByPortPowerThreshold(): Promise<any> {
    return axios.get(baseApiUrl + '/count-by-port-power-threshold');
  }

  public generateOpticalPowerThresholdReport(): Promise<any> {
    return axios.get('/api/device/optical-power-threshold-summary-report', { responseType: 'blob' });
  }

  public generateUnlinkDeviceReport(): Promise<any> {
    return axios.get('api/device/unlink-device-report', { responseType: 'blob' });
  }

  public generateOfflineDeviceReport(isTR069: boolean): Promise<any> {
    return axios.get('api/dashboard/device/offline-device-report', {
      params: { isTR069 },
      responseType: 'blob'
    });
  }

  public devicePortPowerThresholdIssue(thresholdName: string, paginationQuery): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/device-having-port-power-issue?threshold=${thresholdName}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deviceDiscoveryReport(days: number, interval: string) {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/device-discovery-report?days=${days}&interval=${interval}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
