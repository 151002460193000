import Vue from 'vue';
import { Component, Inject, Watch } from 'vue-property-decorator';
import navMenuItems from '@/navigation';
import ability from '@/libs/acl/ability';
import { useDark, useToggle } from '@vueuse/core';
import { IOrganization, Organization } from './shared/model/organization.model';
import OrganizationService from '@/entities/organization/organization.service';
import { resolve } from 'path';
import axios from 'axios';
import UserManagementService from './admin/user-management/user-management.service';
import CommonUtils from './libs/common-utils';
import { IOrgWiseRole } from './shared/model/org-wise-roles.model';

@Component
export default class LayoutVertical extends Vue {
  // @Inject('organizationService')
  private organizationService = () => new OrganizationService();
  public drawer = false;
  public menuItems = navMenuItems;
  public isSaving = false;

  public isDark = useDark();
  public toggleDark = useToggle(this.isDark);
  public organizationList: any = [];
  public selectedOrganization: any = '';
  public organization: IOrganization | any = {};

  public version = sessionStorage?.getItem('version');
  @Inject('userManagementService') private userManagementService: () => UserManagementService;
  public get userMenu() {
    let menuItems = [];
    navMenuItems.forEach(menu => {
      if (menu.children) {
        let subMenuItems = [];
        menu.children.forEach(subMenu => {
          // if (subMenu.title == 'Organization' && this.multiOrganizationView) {
          //   return;
          // }
          if ((!subMenu.resource || ability.can(subMenu.action, subMenu.resource)) && CommonUtils.getFeaturePermissions(subMenu.feature)) {
            subMenuItems.push(subMenu);
          }
        });
        let mainMenu = { ...menu };
        // @ts-ignore
        mainMenu.children = subMenuItems;
        if (subMenuItems.length > 0) menuItems.push(mainMenu);
      } else {
        if (!menu.resource || ability.can(menu.action, menu.resource)) {
          menuItems.push(menu);
        }
      }
    });
    return menuItems;
  }

  public mounted() {}
  public beforeMount(): void {}

  public isShow = false;

  get isMenuVisible(): boolean {
    const hideMenu = sessionStorage.getItem('hideMenu');
    if (hideMenu && hideMenu == 'true') {
      return false;
    }
    return true;
  }
  get userShortName(): string {
    let user = this.$store.getters.account;
    let shortName = '';
    if (user?.firstName) {
      shortName += user.firstName.charAt(0);
    }
    if (user?.lastName) {
      shortName += user.lastName.charAt(0);
    }
    return shortName;
  }

  get userFullName() {
    let user = this.$store.getters.account;
    let fullName = ' ';
    if (user?.firstName) {
      fullName += user.firstName;
    }
    if (user?.lastName) {
      fullName += ' ' + user.lastName;
    }
    return fullName;
  }

  get userEmail() {
    let user = this.$store.getters.account;
    if (user) {
      return user.email;
    }
    return '';
  }

  public logout() {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('CurrentOrganization');
    sessionStorage.removeItem('Features');
    this.userManagementService().logout();
    this.$store.commit('logout');
    if (this.$route.path !== '/login') {
      return this.$router.push('/login').catch(() => true);
    }
    return Promise.resolve(this.$router.currentRoute);
  }
  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }

  public get themeIcon() {
    if (this.isDark.value) {
      return 'mdi-white-balance-sunny';
    }
    return 'mdi-weather-night';
  }
  public toggleDarkMode() {
    this.toggleDark();
    this.$vuetify.theme.dark = this.isDark.value;
    window.location.reload();
  }
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public onOrganizationChange(event) {
    if (event.id != null) {
      this.isSaving = true;
      this.organizationService()
        .switchOrganization(event.id)
        .then(res => {
          this.isSaving = false;
          location.href = `${location.protocol}//${location.host}`;
        });
    }
  }

  get orgList() {
    if (this.$store.getters.account != null) {
      this.organizationList = JSON.parse(sessionStorage.getItem('UserWiseOrganization'));

      this.selectedOrganization = JSON.parse(sessionStorage.getItem('CurrentOrganization'));
      if (this.selectedOrganization == null) {
        return this.organizationList;
      }
      this.organizationService()
        .find(this.selectedOrganization.id)
        .then(res => {
          this.organization = res;
        });
    }
    return this.organizationList;
  }

  public get multiOrganizationView () {
    this.organization = JSON.parse(sessionStorage.getItem('CurrentOrganization'));
    return this.$store.getters.multiOrganizationView;
  };

  isSelected(org) {
    const currentOrg = JSON.parse(sessionStorage.getItem('CurrentOrganization'));
    return currentOrg && currentOrg.id === org.id;
  }

  public loadOrganizations() {
    // this.isShow = true;
    // this.$emit('closeRefreshDialog', this.isShow);
    this.$router.push('/organization'); // Navigate to the "/organization" route
  }
}
