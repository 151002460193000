import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class H8Dialog extends Vue {
    @Prop({default : false}) public openDialog;
    @Prop({default : false}) public fullscreen;
    @Prop({default : '500'}) public maxWidth;
    @Prop({default : 'scale-transition'}) public transition;
    @Prop({default : ''}) public contentClass;
    @Prop() public titleDialog;
    public dialogState;

    @Watch('openDialog')
    onOpenDialogChange(){
        this.dialogState = this.openDialog;
    }
    
    public beforeMount(): void {
        this.dialogState = this.openDialog;
    }
}