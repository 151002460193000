import CommonUtils from '@/libs/common-utils';
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import AlertService from '@/shared/alert/alert.service';
@Component
export default class H8Table extends Vue {
  @Inject('alertService') private alertService: () => AlertService;
  @Prop() public headers: [];
  public items: [] = [];
  @Prop() public h8Service: any;
  @Prop() public filterQuery: any;
  public selected: [] = [];
  @Prop(Boolean) public showSelect: boolean;

  public isFetching = false;
  public previousPage = 1;
  public totalItems = 0;
  public queryCount: number = null;
  public propOrder = 'id';
  public currentSearch = '';
  public reverse = true;
  public page = 1;
  public pageCount = 0;
  public itemsPerPage = 10;
  public options = { itemsPerPage: this.itemsPerPage };
  public currentStartRow = 0;
  public disableSort = false;
  public selectedItems = [];

  @Watch('selected')
  public dataSelected() {
    this.$emit('selectedData', this.selected);
  }

  @Prop() public detailPageRoute: String;
  @Prop({ default: null }) public h8sort;

  @Watch('options', { deep: true })
  public handler() {
    this.retrieveAllEntities();
  }
  public mounted(): void {
    this.options['sortDesc'][0] = true;
    if (this.filterQuery != undefined) {
      this.currentSearch = this.filterQuery.search;
      this.disableSort = true;
    }
    //this.options["itemsPerPage"] = 5;
    // this.headersProp = this.headers;
    // this.itemsProp = this.items;
  }

  public beforeMount(): void {
    if (this.$route.params.page) {
      this.page = +this.$route.params.page;
    }
    this.itemsPerPage = this.getItemsPerPageFromLocalStorage();
    this.options['itemsPerPage'] = this.itemsPerPage;
  }

  @Watch('filterQuery')
  public filter() {
    this.disableSort = false;
    this.currentSearch = '';
    if (this.filterQuery && this.filterQuery.search) {
     this.currentSearch = this.filterQuery.search;
      this.disableSort = true;
    }
    this.retrieveAllEntities();
  }

  public onItemsPerPageChange(event) {
    this.itemsPerPage = parseInt(event, 10);
    this.page = this.currentStartRow == 0 ? 1 : Math.ceil(this.currentStartRow / this.itemsPerPage);
    this.options['itemsPerPage'] = this.itemsPerPage;
    this.setItemsPerPageToLocalStorage({ itemsPerPage: this.options.itemsPerPage });
  }

  public setItemsPerPageToLocalStorage(data: {}) {
    const localStorageData = JSON.stringify(data);
    localStorage.setItem(this.$route.name, localStorageData);
  }

  public getItemsPerPageFromLocalStorage() {
    if (localStorage[this.$route?.name] == null) {
      return this.itemsPerPage;
    }
    let localStorageObject = JSON.parse(localStorage[this.$route.name]);
    if (localStorageObject?.itemsPerPage) {
      return localStorageObject.itemsPerPage;
    }
    return this.itemsPerPage;
  }

  public onPageChange(event) {
    if ('page' in this.$route.params) {
      this.$router.push({ params: { page: event }, query: this.$route.query });
    }
    this.currentStartRow = this.itemsPerPage * (this.page - 1);
    this.options['page'] = event;
  }

  public retrieveAllEntities(): void {
    this.isFetching = true;
    const paginationQuery = {
      page: this.page - 1,
      size: this.options['itemsPerPage'],
      sort: this.sort(),
    };
    if (this.currentSearch) {
      paginationQuery.sort = ['id,desc'];
      if (this.h8sort) {
        paginationQuery.sort = this.h8sort;
      }
      this.h8Service()
        .search(this.currentSearch, paginationQuery)
        .then(
          res => {
            this.items = res.data;
            this.totalItems = Number(res.headers['x-total-count']);
            this.queryCount = this.totalItems;
            this.isFetching = false;
          },
          err => {
            this.isFetching = false;
            //this.alertService().showHttpError(this, err.response);
          }
        );
      return;
    }
    this.h8Service()
      .retrieve(paginationQuery)
      .then(
        res => {
          this.items = res.data;
          this.totalItems = Number(res.headers['x-total-count']);
          this.queryCount = this.totalItems;
          this.isFetching = false;
        },
        err => {
          this.isFetching = false;
          //this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public deleteEntities(removeId) {
    let storeId = removeId;
    this.h8Service()
      .delete(removeId)
      .then(() => {
        this.$emit('messageDeleted', storeId);
        this.retrieveAllEntities();
      });
  }

  public sort(): Array<any> {
    let sortField = this.options['sortBy'].length > 0 ? this.options['sortBy'][0] : 'id';
    let sortOrder = this.options['sortDesc'].length > 0 && this.options['sortDesc'][0] == false ? 'asc' : 'desc';
    const result = [sortField + ',' + sortOrder];
    return result;
  }
  public actionDelete(data) {
    if (data.login) {
      this.deleteEntities(data.login);
    } else {
      this.deleteEntities(data.id);
    }
  }

  public onRowSelect(selectedData) {
    if (this.detailPageRoute != null) {
      if (this.detailPageRoute == 'JhiUserView') {
        return this.$router.push({ name: this.detailPageRoute.toString(), params: { userId: selectedData.login } });
      }
      this.$router.push({ name: this.detailPageRoute.toString(), params: { id: selectedData.id } });
    }
  }
}
