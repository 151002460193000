var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "end", "no-gutters": "" } },
        [
          _c("v-col", [_vm._t("actionSlot")], 2),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { width: "120px" } },
            [
              _c("v-combobox", {
                attrs: {
                  items: [10, 20, 50, 100, 200, 300, 500],
                  label: "Rows per page",
                  outlined: "",
                  dense: "",
                },
                on: { input: _vm.onItemsPerPageChange },
                model: {
                  value: _vm.itemsPerPage,
                  callback: function ($$v) {
                    _vm.itemsPerPage = $$v
                  },
                  expression: "itemsPerPage",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-pagination", {
            attrs: { length: _vm.pageCount, "total-visible": 10 },
            on: { input: _vm.onPageChange },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-2",
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          "server-items-length": _vm.totalItems,
          loading: _vm.isFetching,
          options: _vm.options,
          "hide-default-footer": "",
          dense: "",
          "disable-sort": _vm.disableSort,
          "show-select": _vm.showSelect,
          "item-key": "id",
        },
        on: {
          "update:options": function ($event) {
            _vm.options = $event
          },
          "page-count": function ($event) {
            _vm.pageCount = $event
          },
          "click:row": _vm.onRowSelect,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.actionDelete",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          icon: "",
                                          fab: _vm.$vuetify.breakpoint.mobile,
                                          large: _vm.$vuetify.breakpoint.mobile,
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v(" mdi-delete ")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-2",
                          attrs: { flat: "", "min-width": "12rem" },
                        },
                        [
                          _c("p", { staticClass: "caption" }, [
                            _vm._v("Confirm deletion"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-around",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { small: "", color: "primary" } },
                                [_vm._v("Cancel")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { small: "", color: "error" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.actionDelete(item)
                                    },
                                  },
                                },
                                [_vm._v("Delete")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            _vm._l(Object.keys(_vm.$scopedSlots), function (field) {
              return {
                key: field,
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._t(field, null, { item: item })]
                },
              }
            }),
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }