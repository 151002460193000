var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": _vm.maxWidth,
        fullscreen: _vm.fullscreen,
        transition: _vm.transition,
        "content-class": _vm.contentClass,
      },
      on: {
        input: function (event) {
          return _vm.$emit("closeDialog", event)
        },
      },
      model: {
        value: _vm.dialogState,
        callback: function ($$v) {
          _vm.dialogState = $$v
        },
        expression: "dialogState",
      },
    },
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", height: "35", dense: "", color: "#1b2b3c" } },
        [
          _c("v-toolbar-title", { staticClass: "dialog_title" }, [
            _vm._v(_vm._s(_vm.titleDialog)),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { small: "", icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("closeDialog")
                },
              },
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "pa-2", attrs: { tile: "" } },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }