import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class H8TableGrid extends Vue {
    @Prop({ default: [] }) public items;
    @Prop({ default: [] }) public headers;
    @Prop({ default: '12' }) public cols;
    @Prop({ default: '4' }) public lg;
    @Prop({ default: '4' }) public md;
    @Prop({ default: '6' }) public sm;
    @Prop({ default: null }) public headerName;
    @Prop({ default: null, type: String }) public sortBy;
    @Prop({ default: null, type: Boolean }) public sortDesc;
    @Prop({ default: null, type: Number }) public itemsPerPage;
    @Prop({default: false}) public loading: boolean
    @Prop({default: 'Loading content ...'}) public loadingText;

    public getValue(item, header: string) {
        return header.split('.').reduce((data, value) => data?.[value], item);
    }

}
