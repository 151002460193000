var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex flex-row h-100",
      staticStyle: { "max-width": "100%" },
    },
    [
      _vm.sidebarTitle
        ? _c(
            "div",
            {
              staticClass: "flex-grow-0 d-lg-block d-none mr-4",
              style: _vm.sidebarStyle,
            },
            [
              _c(
                "v-card",
                { attrs: { dark: "", color: "transparent", flat: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c("v-card-title", { staticClass: "subtitle-2" }, [
                        _vm._v(" " + _vm._s(_vm.sidebarTitle) + " "),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._t("sidebar"),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow-1 content" },
        [
          _c(
            "v-card",
            { staticClass: "h-100 transparent-bg-light" },
            [
              _c(
                "v-toolbar",
                { staticClass: "h8toolbar", attrs: { dense: "" } },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c("v-toolbar-title", { staticClass: "toolbar-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-md-block d-none" },
                        [
                          _c(
                            "v-btn-toggle",
                            { attrs: { group: "", rounded: "", dense: "" } },
                            [
                              _vm.backEnable
                                ? _c(
                                    "v-btn",
                                    { on: { click: _vm.previousState } },
                                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._t("action-btn"),
                              _vm._v(" "),
                              _vm.editEnable
                                ? _c(
                                    "v-btn",
                                    { on: { click: _vm.onEditEntities } },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-clipboard-edit"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.deleteEnable
                                ? _c(
                                    "v-menu",
                                    {
                                      attrs: { bottom: "", "offset-y": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _vm.deleteEnable
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-delete"),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3967630966
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "pa-2",
                                          attrs: {
                                            flat: "",
                                            "min-width": "12rem",
                                          },
                                        },
                                        [
                                          _c("p", { staticClass: "caption" }, [
                                            _vm._v("Confirm deletion"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                "no-gutters": "",
                                                justify: "space-around",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "primary",
                                                  },
                                                },
                                                [_vm._v("Cancel")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "error",
                                                  },
                                                  on: {
                                                    click: _vm.onDeleteEntities,
                                                  },
                                                },
                                                [_vm._v("Delete")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.menuVisible
                        ? _c(
                            "div",
                            { staticClass: "d-md-none d-block" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { icon: "" } },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3221905750
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    { attrs: { dense: "" } },
                                    [
                                      _vm.backEnable
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: { click: _vm.previousState },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                { staticClass: "mr-2" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-arrow-left")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-list-item-title", [
                                                _vm._v("Back"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._t("action-btn-list"),
                                      _vm._v(" "),
                                      _vm.editEnable
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: { click: _vm.onEditEntities },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                { staticClass: "mr-2" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-clipboard-edit"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-list-item-title", [
                                                _vm._v("Edit"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              bottom: "",
                                              "offset-y": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _vm.deleteEnable
                                                        ? _c(
                                                            "v-list-item",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                  },
                                                                },
                                                                "v-list-item",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-list-item-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    "Delete"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3655052115
                                            ),
                                          },
                                          [
                                            _vm._v(" "),
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "pa-2",
                                                attrs: {
                                                  flat: "",
                                                  "min-width": "12rem",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "caption" },
                                                  [_vm._v("Confirm deletion")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: {
                                                      "no-gutters": "",
                                                      justify: "space-around",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [_vm._v("Cancel")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: "error",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.onDeleteEntities,
                                                        },
                                                      },
                                                      [_vm._v("Delete")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "h-100 ma-3" },
                [
                  _c(
                    "v-navigation-drawer",
                    {
                      staticClass: "d-block d-lg-none",
                      attrs: {
                        temporary: "",
                        color: "#1b2b3c",
                        app: "",
                        dark: "",
                      },
                      model: {
                        value: _vm.sidebarVisibility,
                        callback: function ($$v) {
                          _vm.sidebarVisibility = $$v
                        },
                        expression: "sidebarVisibility",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                dark: "",
                                color: "transparent",
                                flat: "",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "subtitle-2" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.sidebarTitle) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._t("sidebar"),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._t("default"),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "d-block d-lg-none mb-2",
              attrs: {
                fab: "",
                color: "primary",
                fixed: "",
                right: "",
                bottom: "",
                elevation: "10",
              },
              on: {
                click: function ($event) {
                  _vm.sidebarVisibility = !_vm.sidebarVisibility
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-filter-menu")])],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }