import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component
export default class H8Card extends Vue {
  @Prop() public title: String;
  @Prop() public subTitle: String;
  @Prop({default: true}) public topBorder: String;
  @Prop({default: false}) public loading: boolean;
  @Prop({default: "Loading Content..."}) public loadingText: String;
}
