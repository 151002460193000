// src/plugins/vuetify.js

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '../../content/scss/h8udm.scss';
import { useDark, useToggle } from '@vueuse/core';
import colors from 'vuetify/lib/util/colors';
Vue.use(Vuetify);

//const isDark = useDark();
const isDark = false;
const toggleDark = useToggle(isDark);
const opts = {
  theme: {
    dark: isDark.value,
    themes: {
      light: {
        primary: '#679EE1',
        secondary: '#F9AA33',
        appNavColor: '#1b2b3c',
      },
      dark: {
        primary: '#83A2C7',
        secondary: '#3e3e42',
      },
    },
    options: { customProperties: true },
    // themeCache: {
    //   get: key => localStorage.getItem(key),
    //   set: (key, value) => localStorage.setItem(key, value),
    // },
    // themes: {
    //   light: {
    //     primary: colors.red.darken1,
    //     secondary: colors.red.lighten4,
    //     accent: '#82B1FF',
    //     error: '#FF5252',
    //     info: '#2196F3',
    //     success: '#4CAF50',
    //     warning: '#FB8C00',
    //   },
    //   dark: {
    //     primary: colors.pink.darken1,
    //     secondary: colors.pink.lighten4,
    //     accent: colors.green.lighten4,
    //     error: colors.red.base,
    //     info: colors.blue.base,
    //     success: colors.green.base,
    //     warning: colors.orange.base,
    //   },
    // },
  },
};

export default new Vuetify(opts);
