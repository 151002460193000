import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import goTo from 'vuetify/lib/services/goto';

@Component
export default class H8Window extends Vue {
  @Prop(Boolean) public deleteEnable: boolean;
  @Prop(Boolean) public editEnable: boolean;
  @Prop(Boolean) public backEnable: boolean;
  @Prop(Boolean) public menuVisible: boolean;
  @Prop(Boolean) public refreshVisible: boolean;

  @Prop() public title: string;
  public sidebarVisibility = false;
  @Prop() public sidebarTitle: string;

  public previousState() {
    return (<any>this).$router.go(-1);
  }

  public onRefresh() {
    this.$emit('refreshButtonClicked');
  }
  public onEditEntities() {
    this.$emit('editButtonClicked');
  }

  public onDeleteEntities() {
    this.$emit('deleteButtonClicked');
  }

  public get sidebarStyle(): string {
    let style = '';
    if (this.sidebarTitle != undefined && this.sidebarTitle.trim().length > 0) style = 'min-width: 270px';
    return style;
  }
}
