export const initialAbility = [
  {
    action: 'read',
    subject: 'Home',
  },
  {
    action: 'manage',
    subject: 'Settings',
  },
];

export const _ = undefined;
