import axios from 'axios';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import TranslationService from '@/locale/translation.service';
import ability from '@/libs/acl/ability';
import { IUser } from '@/shared/model/user.model';

export default class AccountService {
  private organizationFeatureAPI: string = 'api/organization-features/search';
  private organizationUrl = 'api/organizations';
  constructor(private store: Store<any>, private translationService: TranslationService, private router: VueRouter) {
    this.init();
  }

  public init(): void {
    this.multiOrganizationView();
    this.retrieveProfiles();
  }

  public retrieveProfiles(): Promise<boolean> {
    return new Promise(resolve => {
      axios
        .get<any>('management/info')
        .then(res => {
          if (res.data && res.data.activeProfiles) {
            this.store.commit('setRibbonOnProfiles', res.data['display-ribbon-on-profiles']);
            this.store.commit('setActiveProfiles', res.data['activeProfiles']);
          }
          resolve(true);
        })
        .catch(() => resolve(false));
    });
  }

  public async retrieveAccount(): Promise<boolean> {
    return new Promise(resolve => {
      axios
        .get<any>('api/account')
        .then(async response => {
          this.store.commit('authenticate');
          const account = response.data;
          if (account) {
            await this.abilities();
             if(account.id != null){
              await this.getOrgWiseRoles(account.id);
            }
            await this.getOrgFromSession();
            this.store.commit('authenticated', account);
            if (this.store.getters.currentLanguage !== account.langKey) {
              this.store.commit('currentLanguage', account.langKey);
            }
            if (sessionStorage.getItem('requested-url')) {
              this.router.replace(sessionStorage.getItem('requested-url'));
              sessionStorage.removeItem('requested-url');
            }
          } else {
            this.store.commit('logout');
            if (this.router.currentRoute.path !== '/login') {
              this.router.push('/login').catch(() => true);
            }
            sessionStorage.removeItem('requested-url');
          }
          this.translationService.refreshTranslation(this.store.getters.currentLanguage);
          resolve(true);
        })
        .catch(() => {
          this.store.commit('logout');
          resolve(false);
        });
    });
  }

  public hasAnyAuthorityAndCheckAuth(resource: string, action: string): Promise<boolean> {
    if (!this.authenticated) {
      let token = localStorage.getItem('jhi-authenticationToken');
      if (!token) {
        token = sessionStorage.getItem('jhi-authenticationToken');
      }
      if (!this.store.getters.account && !this.store.getters.logon && token) {
        return this.retrieveAccount().then(resp => {
          if (resp) {
            return this.checkAuthorities(resource, action);
          }
          return Promise.resolve(false);
        });
      }
      return Promise.resolve(false);
    }
    return this.checkAuthorities(resource, action);
  }

  public get authenticated(): boolean {
    return this.store.getters.authenticated;
  }

  public getCurrentUser(): IUser {
    return this.store.getters.account;
  }
  public get userAuthorities(): any {
    return this.store.getters.account?.authorities;
  }

  private checkAuthorities(resource: string, action: string): Promise<boolean> {
    if (!resource || !action) {
      return Promise.resolve(true);
    }
    return Promise.resolve(ability.can(action, resource));
  }

  public abilities() {
    let userAbilities = [];
    return axios.get<any>('api/admin/users/abilities').then(response => {
      let abilities = response.data
        .filter(userAbility => userAbility.can == true)
        .map(userAbility => ({ action: userAbility.action, subject: userAbility.resource }));
      abilities.forEach(userAbility => {
        userAbilities.push(userAbility);
      });
      ability.update(userAbilities);
    });
  }

  public features(orgId) {
    return axios.get<any>(`${this.organizationFeatureAPI}/${orgId}`).then(response => {
      sessionStorage.setItem('Features', JSON.stringify(response.data));
    });
  }

  public multiOrganizationView() {
    return axios.get<any>(`public/multi-organization-view`).then(res => {
      this.store.commit('setMultiOrganizationView', res.data);
    }); 
  }

  public getOrgFromSession() {
    return new Promise<any>((resolve, reject) => {
      console.log('Going to Set Organization');

      axios
        .get(`${this.organizationUrl}/fetch-session-org`)
        .then(res => {
          sessionStorage.setItem('CurrentOrganization', JSON.stringify(res.data));
          console.log('Going to Setted Organization');
          this.features(res.data.id);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getOrgWiseRoles(userId: any) {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this.organizationUrl}/orgsbyuser/${userId}`)
        .then(res => {
          sessionStorage.setItem('UserWiseOrganization', JSON.stringify(res.data));
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getVersion(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get('public/version')
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
