import Vue from 'vue';
import router from '../router';

export default class LoginService extends Vue {
  public openLogin(instance: Vue): void {
    instance.$emit('bv::show::modal', 'login-page');
  }

  public logout() {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    //this.$store.commit('logout');
    router.push('/login').catch(() => true);
  }
}
