var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c("div", {
        staticStyle: {
          width: "100vw",
          height: "20px",
          "background-color": "#224474",
          clear: "both",
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          width: "100vw",
          height: "10vh",
          "background-color": "#ffffff",
          clear: "both",
          "background-image":
            "linear-gradient(to top, #B4CFF3 0%, #FCFEFF 100%)",
        },
      }),
      _vm._v(" "),
      _c(
        "v-row",
        {
          attrs: {
            id: "wheet_img",
            "no-gutters": "",
            justify: "center",
            "align-content": "center",
          },
        },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          width: "100vw",
          height: "10vh",
          "background-color": "#ffffff",
          clear: "both",
          "background-image":
            "linear-gradient(to top, #B4CFF3 0%, #FCFEFF 100%)",
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          width: "100vw",
          height: "20px",
          "background-color": "#224474",
          clear: "both",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }