var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "v-row",
        {
          attrs: {
            "align-content": "center",
            justify: "center",
            "no-gutters": "",
          },
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-overlay",
                { attrs: { value: _vm.isVisible } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "#153E7E", size: "35" },
                  }),
                  _vm._v(" "),
                  _vm.loadingText
                    ? _c("div", { staticClass: "text-center pt-3" }, [
                        _vm._v(_vm._s(_vm.loadingText)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }