var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "d-block d-lg-none",
          attrs: {
            app: "",
            temporary: "",
            dark: !_vm.$vuetify.theme.dark,
            color: "appNavColor",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _vm.$store.getters.authenticated
            ? _c(
                "v-list",
                { attrs: { dense: "" } },
                _vm._l(_vm.userMenu, function (item) {
                  return _c(
                    "div",
                    [
                      item.children
                        ? _c(
                            "v-list-group",
                            {
                              attrs: {
                                "prepend-icon": item.icon,
                                "no-action": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(item.title)),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _vm._l(item.children, function (children, i) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: i,
                                    attrs: {
                                      link: "",
                                      to: children.route,
                                      value: i,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(children.icon)),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(children.title)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        : _c(
                            "v-list-item",
                            { attrs: { to: item.route } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$store.getters.authenticated && _vm.isMenuVisible
        ? _c(
            "v-app-bar",
            {
              staticStyle: { "padding-left": "0px" },
              attrs: {
                app: "",
                dense: !_vm.$vuetify.breakpoint.mobile,
                elevation: "4",
                fixed: "",
                dark: !_vm.$vuetify.theme.dark,
                color: "appNavColor",
              },
            },
            [
              _c("v-app-bar-nav-icon", {
                staticClass: "d-block d-lg-none",
                attrs: { app: "" },
                on: {
                  click: function ($event) {
                    _vm.drawer = !_vm.drawer
                  },
                },
              }),
              _vm._v(" "),
              _vm.organization.logo
                ? _c("v-img", {
                    staticClass: "mx-1",
                    attrs: {
                      src:
                        "data:" +
                        _vm.organization.logoContentType +
                        ";base64," +
                        _vm.organization.logo,
                      contain: "",
                      "max-height": "100%",
                      "max-width": "120px",
                    },
                  })
                : _c("v-img", {
                    staticClass: "mx-1",
                    attrs: {
                      src: require("../content/images/logo_white.png"),
                      contain: "",
                      "max-height": "100%",
                      "max-width": "100px",
                    },
                  }),
              _vm._v(" "),
              _vm.$store.getters.authenticated && _vm.isMenuVisible
                ? _c(
                    "v-slide-group",
                    {
                      staticClass: "d-none d-lg-block",
                      attrs: {
                        multiple: "",
                        "show-arrows": "",
                        dark: !_vm.$vuetify.theme.dark,
                      },
                    },
                    _vm._l(_vm.userMenu, function (item) {
                      return item.title
                        ? _c("v-slide-item", {
                            key: item.title,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var active = ref.active
                                    var toggle = ref.toggle
                                    return [
                                      item.children
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "open-on-hover": "",
                                                "offset-y": "",
                                                dark: !_vm.$vuetify.theme.dark,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  tile: "",
                                                                  plain: "",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n              " +
                                                                _vm._s(
                                                                  item.title
                                                                ) +
                                                                "\n              "
                                                            ),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  right: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                " +
                                                                    _vm._s(
                                                                      item.icon
                                                                    ) +
                                                                    "\n              "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-list",
                                                {
                                                  attrs: {
                                                    color: "appNavColor",
                                                  },
                                                },
                                                _vm._l(
                                                  item.children,
                                                  function (subMenu, index) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          link: "",
                                                          to: subMenu.route,
                                                          dense: "",
                                                        },
                                                      },
                                                      [
                                                        subMenu.icon
                                                          ? _c(
                                                              "v-list-item-icon",
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      subMenu.icon
                                                                    )
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                subMenu.title
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                tile: "",
                                                plain: "",
                                                to: item.route,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n          " +
                                                  _vm._s(item.title) +
                                                  "\n          "
                                              ),
                                              _c(
                                                "v-icon",
                                                { attrs: { right: "" } },
                                                [
                                                  _vm._v(
                                                    "\n            " +
                                                      _vm._s(item.icon) +
                                                      "\n          "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e()
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              [
                _c("div", [
                  _c("div", { staticClass: "d-inline pr-3" }, [
                    !_vm.inProduction
                      ? _c(
                          "a",
                          {
                            staticStyle: { "text-decoration": "none" },
                            attrs: { href: "/h2-console/", target: "_tab" },
                          },
                          [_c("v-icon", [_vm._v("mdi-database-check")])],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.$can("view", "Application Metrics")
                    ? _c(
                        "div",
                        { staticClass: "d-inline pr-3" },
                        [
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({
                                    name: "JhiMetricsComponent",
                                  })
                                },
                              },
                            },
                            [_vm._v("mdi-gauge")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  attrs: {
                    bottom: "",
                    "min-width": "200px",
                    rounded: "",
                    "offset-y": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "btncrm",
                                  attrs: {
                                    fab: "",
                                    small: "",
                                    color: "primary",
                                  },
                                },
                                on
                              ),
                              [
                                _c("v-avatar", { attrs: { size: "36" } }, [
                                  _c("span", { staticClass: "text-h6" }, [
                                    _vm._v(_vm._s(_vm.userShortName)),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    946551522
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "pa-2" },
                    [
                      _c(
                        "v-list",
                        [
                          _c("v-list-item", [
                            _c("div", { staticClass: "mx-auto text-center" }, [
                              _c("h3", [_vm._v(_vm._s(_vm.userFullName))]),
                              _vm._v(" "),
                              _c("p", { staticClass: "text-caption mt-1" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.userEmail) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "py-1" }),
                          _vm._v(" "),
                          _vm.multiOrganizationView
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _vm.organization
                                        ? _c(
                                            "v-list-item",
                                            {
                                              key: _vm.organization.id,
                                              class: {
                                                "selected-org": _vm.isSelected(
                                                  _vm.organization
                                                ),
                                              },
                                              attrs: { link: "", dense: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onOrganizationChange(
                                                    _vm.organization
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-domain"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "selected-text primary--text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.organization.name
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.orgList
                                          .filter(function (o) {
                                            return o.id !== _vm.organization.id
                                          })
                                          .slice(0, 4),
                                        function (org) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: org.id,
                                              class: {
                                                "selected-org":
                                                  _vm.isSelected(org),
                                              },
                                              attrs: { link: "", dense: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onOrganizationChange(
                                                    org
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-domain"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  class: {
                                                    "selected-text":
                                                      _vm.isSelected(org),
                                                    "primary--text":
                                                      _vm.isSelected(org),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(org.name) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider", { staticClass: "py-1" }),
                                  _vm._v(" "),
                                  _vm.$can("view", "Organization")
                                    ? _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "", dense: "" },
                                          on: { click: _vm.loadOrganizations },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-domain"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-list-item-title", [
                                            _vm._v("Show All Organizations"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$can("view", "Organization")
                                    ? _c("v-divider", { staticClass: "py-1" })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.authenticated
                            ? _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    link: "",
                                    dense: "",
                                    to: "/account/settings/general-settings",
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-cog")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Settings "),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "", dense: "" },
                              on: { click: _vm.logout },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-logout")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Logout")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h8-overlay", { attrs: { isVisible: _vm.isShow } }),
      _vm._v(" "),
      _c(
        "v-main",
        {
          class: _vm.$vuetify.theme.dark
            ? "dark-background"
            : "light-background",
          staticStyle: { height: "100%" },
        },
        [
          _c(
            "v-container",
            { staticStyle: { height: "100%" }, attrs: { fluid: "" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-footer",
        {
          staticStyle: { "box-shadow": "inset 0 1px 10px #000000" },
          attrs: { app: "", fixed: "", padless: "", height: "24px" },
        },
        [
          _c(
            "span",
            { staticStyle: { "margin-left": "10px", "font-size": "small" } },
            [_vm._v("Version: " + _vm._s(_vm.version) + " ")]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "span",
            { staticStyle: { "margin-right": "10px", "font-size": "small" } },
            [
              _vm._v("©Powered by:\n      "),
              _c(
                "a",
                {
                  staticClass: "ml-25",
                  attrs: {
                    href: "https://www.height8tech.com",
                    rel: "noopener",
                    target: "_blank",
                  },
                },
                [_vm._v("Height8 Technologies")]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }