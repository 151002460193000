var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "glass",
      class: _vm.topBorder ? "top-border" : "",
      staticStyle: { "max-width": "100%" },
      attrs: { rounded: "" },
    },
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "text-h6" }, [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
              ]),
              _vm._v(" "),
              _vm.subTitle
                ? _c("v-list-item-subtitle", [_vm._v(_vm._s(_vm.subTitle))])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("actionButtons"),
        ],
        2
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _vm.loading
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "align-content": "center",
                        justify: "center",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-2", attrs: { cols: "12" } },
                        [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: "", rounded: "" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center loading-text",
                          attrs: { cols: "12" },
                        },
                        [_vm._v(" " + _vm._s(_vm.loadingText) + " ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._t("loading"),
                ],
                2
              )
            : _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }