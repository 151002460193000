import { Authority } from '@/shared/security/authority';

const Register = () => import('@/account/register/register.vue');
const Activate = () => import('@/account/activate/activate.vue');
const ResetPasswordInit = () => import('@/account/reset-password/init/reset-password-init.vue');
const ResetPasswordFinish = () => import('@/account/reset-password/finish/reset-password-finish.vue');
const ChangePassword = () => import('@/account/change-password/change-password.vue');
const Settings = () => import('@/account/settings/settings.vue');
const AccountSettings = () => import('@/account/settings/account-settings.vue');
const AccountSettingGeneral = () => import('@/account/settings/account-setting-general.vue');
const AccountSettingPassword = () => import('@/account/settings/account-setting-password.vue')
export default [
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/account/activate',
    name: 'Activate',
    component: Activate,
  },
  {
    path: '/account/reset/request',
    name: 'ResetPasswordInit',
    component: ResetPasswordInit,
  },
  {
    path: '/account/reset/finish',
    name: 'ResetPasswordFinish',
    component: ResetPasswordFinish,
    meta:{
      layout: 'full'
    }
  },
  {
    path: '/account/password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/account/profile',
    name: 'Profile',
    component: Settings,
    meta: {
      authorities: [Authority.USER],
      pageTitle: 'Account Settings',
    },
  },
  {
    path: '/account/settings',
    name: 'Settings',
    component: AccountSettings,
    redirect:'/account/settings/general-settings',
    
    children: [
      {
        path: 'general-settings',
        name: 'AccountSettingGeneral',
        component: AccountSettingGeneral,
        meta: {
          authorities: [Authority.USER],
          pageTitle: 'General',
        }
      },
      {
        path: 'change-password',
        name: 'AccountSettingPassword',
        component: AccountSettingPassword,
        meta: {
          authorities: [Authority.USER],
          pageTitle: 'Change Password',
        }
      },
    ],
    meta: {
      authorities: [Authority.USER],
      pageTitle: 'Account Settings',
    },
  },
];
